<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>

        <el-form ref="form" :model="formData" :rules="rules" label-width="100px">


            <el-form-item prop="title" label="课程标题">
                <el-input size="small" v-model="formData.title" placeholder="课程标题"></el-input>
            </el-form-item>

            <el-form-item prop="course_count" label="课程节数">
                <el-input size="small" v-model="formData.course_count" placeholder="课程节数"></el-input>
            </el-form-item>

            <el-form-item prop="describe" label="课程描述">
                <el-input type="textarea" rows="3" placeholder="请输入内容" v-model="formData.describe" maxlength="500" show-word-limit> </el-input>
            </el-form-item>

            <el-form-item label="封面图" prop="cover_image" label-width="100px">
                <file-upload-img :url.sync="formData.cover_image"></file-upload-img>
                <span>(290 * 240)</span>
            </el-form-item>

            <el-form-item label="排序值" prop="sort" label-width="100px">
                <el-input-number v-model="formData.sort" :min="0" label="排序值"></el-input-number>
           </el-form-item>


            <el-form-item label="咨询数" prop="zx_count" label-width="100px">
                <el-input-number v-model="formData.zx_count" :min="0" label="咨询数"></el-input-number>
           </el-form-item>

            <el-form-item label=" " label-width="100px">
                <el-button size="small" type="" @click="cancel">取消</el-button>
                <el-button size="small" type="primary" @click="save">保存</el-button>
            </el-form-item>
        </el-form>
        <div style="height:40px;"></div>
    </div>
</template>

<script>
import {addCourse, editCourse, detail} from './api'
import FileUploadImg from '@/components/uploader/uploader-img.vue'

export default {
    name: 'AddOrEdit',
    components: {
        FileUploadImg
    },
    data() {
        return {
            options: [],
            formData: {
                title: '',
                cover_image: '',
                course_count: '',
                describe: '',
                sort: 0,
                zx_count: 0
            },
            teacherList: [
                {
                    id: 0,
                    name: '请选择'
                }
            ],
            rules: {
                title: [{required: true, message: '课程标题必填', trigger: 'blur'}],
            }
        }
    },

    mounted() {
        if (this.$route.params.id) this.getDetail()
    },

    methods: {
        getUploadUrl({fileUrl}) {
            this.formData.cover_image = fileUrl
        },
        updateStatus(val) {
            this.formData.status = val
        },
        save() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    console.log(this.formData)
                    if (this.formData.id) {
                        // 编辑
                        await editCourse(this.formData).then(() => {
                            this.$message.success('编辑成功')
                            this.$router.back()
                        })
                    } else {
                        // 新增
                        await addCourse(this.formData).then(() => {
                            this.$message.success('添加成功')
                            this.$router.back()
                        })
                    }
                } else {
                    return false
                }
            })
        },
        async getDetail() {
            this.formData = await detail(this.$route.params.id)
            this.formData.is_limit_time = this.formData.is_limit_time.toString()
        },
        async getTeacherList() {
            this.teacherList = await getAllList()
        },
        cancel() {
            this.$router.back(-1)
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }

    .course-editor {
      .el-form-item__content {
        width: 700px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
